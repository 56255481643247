









































































import { isMasked } from '@/utils';
import { mixins } from "vue-class-component";
import { CtrErrorContext, UNKNOWN } from '@/types';
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { AllocationErrorsMixin } from "@/mixins/allocation-errors-mixin";
import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { Hospital } from '@/store/hospitals/types';
import { OrganCodeValue } from '@/store/lookups/types';
import { DeceasedDonor } from '@/store/deceasedDonors/types';
import { organCodeLookup, TranslationContext } from '@/types';
import { TranslationUtilsMixin } from "@/mixins/translation-utils-mixin";
import { Allocation, AllocationRecipient, RUN_ALLOCATION, RERUN_ALLOCATION, RUN_EXPEDITED_ALLOCATION, RERUN_EXPEDITED_ALLOCATION, DISCONTINUED, SUPERCEDED } from '@/store/allocations/types';
import SubSection from '@/components/shared/SubSection.vue';

@Component({
  components: {
    SubSection,
  }
})
export default class AllocationDetails extends mixins(TranslationUtilsMixin, DateUtilsMixin, AllocationErrorsMixin) {
  @State(state => state.hospitals.all) hospitals!: Hospital[];
  @State(state => state.deceasedDonors.selected) private donor!: DeceasedDonor;

  @Getter('clientId', { namespace: 'deceasedDonors' }) private clientId!: string;
  @Getter('selectedAllocation', { namespace: 'allocations' }) private allocation!: Allocation;
  @Getter('isProvincialAllocation', { namespace: 'allocations' }) private isProvincialAllocation!: boolean;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('specialConsiderations', { namespace: 'allocations' }) private specialConsiderations!: TranslationContext[];
  @Getter('describeAllocationStage', { namespace: 'deceasedDonors' }) private describeAllocationStage!: (allocation: Allocation) => string;

  /**
   * Returns the cause of death
   *
   * Use the lookupValue function to get the cause of death
   *
   * @returns {string} cause of death or ''
   */
  get causeOfDeath(): string {
    const donorDeath = this.donor.death;
    if (!donorDeath || !donorDeath.cause_of_death_code) {
      return '';
    }
    return this.lookupValue(donorDeath.cause_of_death_code.toString(), 'cause_of_death_donor');
  }
  
  /**
   * Return the organ value which will get translated in the template
   *
   * @returns {string} organ value from the lookup
   */
  get organName(): string {
    if (!this.allocation.organ_code) return '-';
    return this.lookupValue((this.allocation.organ_code.toString() || ''), 'organ');
  }

  /**
   * Returns the allocation stage
   *
   * @returns {string} the allocation stage
   */
  get allocationStage() {
    return this.describeAllocationStage(this.allocation);
  }

  /**
   * Returns the allocation timestamp in the consistent UI displayed format.
   *
   * @returns {string} Formatted allocation created at datetime
   */
  get allocationTime() {
    return this.parseDisplayDateTimeUiFromDateTime(this.allocation.created_at);
  }

  /**
   * Returns true if the allocation is a provincial kidney
   *
   * @returns {boolean} true if provincial kidney
   */
  get showOntarioHspKidneyExportThreshold(): boolean {
    return this.allocation.organ_code === OrganCodeValue.Kidney && this.isProvincialAllocation;
  }
  
  /**
   * Return the option param from the url
   *
   * @returns {string} option param from the url
   */
  get organAllocationOption(): string {
    return (this.$route.params.option || '').toString().toLowerCase();
  }

  // Get an array containing any 'CTR Unavailable' entries in special consideration 
  get ctrUnavailableErrors(): CtrErrorContext[] {
    if (!this.allocation) return [];

    const ctrErrors = this.parseCtrUnavailableFromAllocation(this.allocation) || [];
    return ctrErrors;
  }

  // Do we need to show the special considerations 'more details' indicator?
  get showSpecialConsiderationsDetailIndicator(): boolean {
    const hasCtrUnavailableConsideration = this.ctrUnavailableErrors.length > 0;
    const userAuthorizedToManageAllocations = this.checkAllowed("/donors/:donor_id/organs/:organ_id/allocations/:id/rerun", "POST");
    return hasCtrUnavailableConsideration && userAuthorizedToManageAllocations;
  }

  // Which allocation recommendation flow is associated with this allocation list?
  get allocationActionId(): string {
    let actionId = UNKNOWN;
    if (!this.allocation) return actionId;

    // This is based on Expedited vs Non-Expedited as well as Run vs Re-run
    const isExpedited = this.allocation.expedited || false;
    const isReRun = !!this.allocation.supercedes_id || false;

    if (isExpedited) {
      if (isReRun) {
        actionId = RERUN_EXPEDITED_ALLOCATION;
      } else {
        actionId = RUN_EXPEDITED_ALLOCATION;
      }
    } else {
      if (isReRun) {
        actionId = RERUN_ALLOCATION;
      } else {
        actionId = RUN_ALLOCATION;
      }
    }

    return actionId;
  } 

  // Display text for "Ontario HSP Kidneys at Export Threshold?" field
  // NOTE: in some scenarios this information is not provided by CTR (see B#15460)
  get describeExportThreshold(): string {
    const isAtExportThreshold = this.allocation?.donor?.donor_at_threshold;

    // Show masked value if that is what is in response
    if (isMasked(isAtExportThreshold)) return `${isAtExportThreshold}`;

    // Use triple-equals comparison and explict boolean values to distinguish between No (false) and blank (null or undefined)
    let result = '-'; // default to blank
    if (isAtExportThreshold === true) {
      result = this.$t('yes').toString();
    } else if (isAtExportThreshold === false) {
      result = this.$t('no').toString();
    }
    return result;
  }

  // Show more details related to special considerations
  private openSpecialConsiderationsDetail(): void {
    if (this.ctrUnavailableErrors.length === 0) return;

    this.displayAllocationErrorOutcomeNotification(this.allocationActionId, this.ctrUnavailableErrors);
  }
}
