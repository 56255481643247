








































































































import { urlParams } from '@/utils';
import { SaveResult } from '@/types';
import { State, Getter }  from 'vuex-class';
import TextInput from '@/components/shared/TextInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ListDonor, StateDonorList } from '@/store/deceasedDonors/types';
import { VueTagsInput, createTag, createTags } from '@johmun/vue-tags-input';

interface PerformSpecifiedVxmState {
  recipientId: string|null;
  donorId: string|null;
  // Variables for tag inputs
  donorIdInput: string;
  donorIdTags: { text: string, tiClasses: string[] }[];
}

// Constants related to recipient/donor ID searching
const INDEX_PAGE_SIZE = 25;
const INDEX_PAGE = 1;

@Component({
  components: {
    TextInput,
    SubSection,
    SaveToolbar,
    ModalSection,
    VueTagsInput,
  }
})
export default class HlaPerformSpecifiedVirtualCrossmatch extends Vue {
  @Prop({ required: false }) recipientId?: string; // Locks Recipient ID selection
  @Prop({ required: false }) donorId?: string; // Locks Donor ID selection

  @State(state => state.deceasedDonors.donorsList) donors!: StateDonorList;
  @State(state => state.pageState.currentPage.performSpecifiedVxm) editState!: PerformSpecifiedVxmState;

  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;

  private saving = false;

  // Open or close the modal
  private toggleModal(): void {
    const targetModal = this.$refs.performSpecifiedVxmModal as ModalSection;
    targetModal.toggleModal();
  }

  // Load data
  private mounted(): void {
    this.loadDonors();
  }

  // Identify a Donor ID to use in the search query
  get donorIdForQuery(): string {
    // If there is a tag, then use that (only option if value was pasted)
    const donorIdTags = this?.editState?.donorIdTags || [];
    if (donorIdTags.length > 0) {
      // We only allow one entry, so getting the first item should be sufficient
      const donorIdTagText = donorIdTags[0]?.text;
      return donorIdTagText || '';
    }

    // If there is no tag, then we should be using the tag text input entry value
    const donorIdInput = this?.editState?.donorIdInput;
    return donorIdInput || '';
  }

  // Load donors based on Donor ID input
  // Note: here we need to use "TGLN ID" i.e. deceased_donor_id
  private loadDonors(): Promise<void> {
    const donorId = this.donorIdForQuery;
    return new Promise<void>((resolve, reject) => {
      const query = urlParams({
        deceased_donor_id: donorId,
      });
      const search_params = query ? `&${query}` : '';
      const opts = {
        pageNumber: INDEX_PAGE,
        pageSize: INDEX_PAGE_SIZE,
        search_params,
      };
      this.$store.dispatch('deceasedDonors/getList', opts).then(() => {
        resolve();
      }).catch(() => {
        reject();
      });
    });
  }

  // Reload donor options before adding tags, as this will affect validations
  private beforeDonorIdChanged(newTag: any): void {
    // Search donors for Donor ID
    this.loadDonors().then(() => {
      // Add the tag after the search has completed, so that validations are handled as expected
      newTag.addTag();
    });
  }

  // Initialize the form when the modal is opened
  private openModal(): void {
    (this.$refs.performSpecifiedVxmValdiations as any).reset();
    this.initializeForm();
    this.toggleModal();
  }

  // Commit UI form interface data to edit state based on selected API data
  private initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'performSpecifiedVxm',
      value: this.buildPerformSpecifiedVxmState(),
    });
    this.initializeTagInputs();
  }

  // Set initial tag objects based on underlying model variables
  private initializeTagInputs(): void {
    // Donor ID
    const donorIdTags = this.editState.donorId ? createTags(this.editState.donorId) : [];
    Vue.set(this.editState, 'donorIdTags', donorIdTags);
  }

  // Array of donor TGLN IDs included in the filter options
  get donorIDsIncluded(): string[] {
    if (!this.donors) return [];

    const entries = this.donors?.entries || [];
    const mapped = entries.map((donor: ListDonor): string => {
      return `${donor.deceased_donor_id}`;
    });
    return mapped;
  }

  get donorIdAutocompleteOptions(): { text: string }[] {
    // Match options from query filters
    const options: string[] = this.donorIDsIncluded;
    const autocompleteOptions: { text: string }[] = options.map((option: string): { text: string } => {
      return {
        text: option,
      };
    });
    return autocompleteOptions;
  }

  // Limit dropdown options to values that match input
  get filteredDonorIdAutocompleteOptions(): { text: string }[] {
    const options = this.donorIdAutocompleteOptions || [];
    const input = this?.editState?.donorIdInput;
    if (!this.editState || !input) {
      return options;
    }
    const filtered = options.filter((option: { text: string }) => {
      return option.text.toLowerCase().indexOf(input.toLowerCase()) !== -1;
    });
    return filtered;
  }

  // Validate manual entry of donor ID to match list of included values
  private validateDonorIdTag(): { classes: string, rule: any }[] {
    const _donorIDsIncluded = [...this.donorIDsIncluded];
    return [
      {
        classes: 'invalid-donor-id',
        rule: ((tag: { text: string }) => {
          return !_donorIDsIncluded.includes(tag.text.toUpperCase());
        }),
      },
      {
        classes: 'only-numbers',
        rule: /^([0-9]*)$/,
      }
    ];
  }

  // Setup empty form interface
  private buildPerformSpecifiedVxmState(): PerformSpecifiedVxmState {
    const form: PerformSpecifiedVxmState = {
      recipientId: this.recipientId || null,
      donorId: this.donorId || null,
      // variables for tag inputs (will be re-generated based on above)
      donorIdInput: '',
      donorIdTags: [],
    };
    return form;
  }

  // Update donor ID model based on tags
  private onDonorIdChanged(tags: { text: string }[]): void {
    const donorIds = tags.map((tag: { text: string}) => {
      return tag.text;
    });
    const firstDonorId = donorIds.length > 0 ? donorIds[0] : null;
    const donorIdTags = createTags(donorIds, this.validateDonorIdTag());
    Vue.set(this.editState, 'donorId', firstDonorId);
    Vue.set(this.editState, 'donorIdTags', donorIdTags);
  }

  /*
   * In order to ensure that the autocomplete options correspond to the input,
   * we must reload the donors after the 'embedded text input' v-model changes.
   *
   * Note: this also impacts which tag entries are considered valid
   */
  @Watch('editState.donorIdInput')
  private onDonorIdInputChanged(): void {
    this.loadDonors();
  }

  // Initiate specified VXM request
  private confirmSpecifiedVxm(): void {
    this.toggleModal();
    this.saving = true;
    // Refer to the save provider that handles this form area
    const saveToolbar = this.$refs.performSpecificVxmSaveButton as SaveToolbar;
    saveToolbar.startSaving();
    // Setup saving payload
    const payload = {
      recipientId: this.editState?.recipientId,
      donorId: this.editState?.donorId,
    };
    // Dispatch save action and register the response
    this.$store.dispatch('history/createRecipientVxm', payload).then((success: SaveResult) => {
      // Reload recipient, HLA labs, and HLA stem cell therapy procedure
      this.$store.dispatch('history/loadRecipientVxm', this.recipientId).then(() => {
        this.$store.dispatch('history/selectLatestRecipientVxm').then(() => {
          this.saving = false;
          saveToolbar.stopSaving(success);
          // Scroll to the new VXM results
          this.$store.dispatch('utilities/scrollTo', '#hla-selected-vxm');
        });
      }).catch((error:SaveResult) => {
        this.$store.commit('history/clearSelectedVxm');
        this.saving = false;
        saveToolbar.stopSaving(error);
      });
    }).catch((error:SaveResult) => {
        this.$store.commit('history/clearSelectedVxm');
      this.saving = false;
      saveToolbar.stopSaving(error);
    });
  }
}
