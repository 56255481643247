

























import { State, Getter } from 'vuex-class';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { organCodeLookup } from '@/types';
import { Recipient } from '@/store/recipients/types';
import { RecipientJourney } from '@/store/recipientJourney/types';

@Component
export default class SelectedRecipientJourneyLinksOop extends Vue {
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) private journey!: RecipientJourney;

  @Getter('selectedRecipientJourneysList', { namespace: 'recipients' }) private selectedRecipientJourneysList!: { id: string; name: string, organCode: number }[];

  @Prop() journeyId!: string;
  @Prop() journeyName!: string;
  @Prop() organCode!: number;

  show = false;

  public toggleNav() {
    this.show = !this.show;
  }

  public mounted() {
    this.show = this.isActive;
  }

  public toggleSideNav(): void {
    if (window.innerWidth < 992) { 
       this.$emit("toggle");
    }
  }

  @Watch('$route', { immediate: true, deep: true })
  public onUrlChange() {
    this.show = this.isActive;
  }

  // Hide organ specific deatils from side nav
  public ORGAN_CODES_TO_EXCLUDE = ['7'];

  get showOrganSpecificDetails(): boolean {
    // Check if the journey organ code should be excluded
    const isExcluded = this.ORGAN_CODES_TO_EXCLUDE.includes(this.organCode!.toString());
    // Hide field if organ is excluded
    return !isExcluded;
  }

  get isActive(): boolean {
    const organId = this.$route.params.organ_id;
    if (!!organId) {
      return this.journeyId == organId;
    }
    return false;
  }
  
  /**
   * Return organ name from the organ code using organCodeLookup
   * 
   * @returns {string} organ name 
   */
  get organName(): string {
    if (!this.organCode) {
      return '';
    }
    return organCodeLookup[this.organCode] || '';
  }

  public getUrl(hash: string): string {
    hash = `#${hash}`;
    if(this.isActive) {
      return hash;
    } else {
      // We are fine not checking for the existence of client_id as for new recipients we only ever show this in active mode
      const location = this.$router.resolve({ name: 'edit-organ-oop', params: { organ_id: this.journeyId }, hash: hash });
      return location.href;
    }
  }
}
