































































































import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State }  from 'vuex-class';
import { Recipient } from '@/store/recipients/types';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import CardSection from '@/components/shared/CardSection.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { LiverDetails } from '@/store/organSpecificDetails/types';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import DiseasesSection from '@/components/organs/liver/DiseasesSection.vue';
import TherapiesSection from '@/components/organs/liver/TherapiesSection.vue';
import { LiverPeldLab } from '@/store/organSpecificDetails/types';
import { LiverSpecificForm } from '@/components/organs/liver/LiverSpecificDetails.vue';
import { ObjectId } from '@/store/types';

export interface SodiumPeldForm {
  _id?: { $oid: string };
  dateOfPeldData?: string;
  peldExpiryDate?: string;
  serumBilirubin?: number;
  inr?: number;
  albumin?: number;
  growthFailure?: boolean;
  peldScore?: number;
}

interface LiverPeldRow {
  _id?: { $oid: string };
  dateOfPeldData?: string;
  peldExpiryDate?: string;
  serumBilirubin?: string;
  inr?: string;
  albumin?: string;
  growthFailure?: string;
  peldScore?: string;
}

@Component({
  components: {
    TextInput,
    DateInput,
    SubSection,
    CardSection,
    NumberInput,
    CheckboxInput,
    TextAreaInput,
    DiseasesSection,
    TherapiesSection,
  }
})
export default class SodiumPeld extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.liverDetails) editState!: LiverSpecificForm;
  @State(state => state.organSpecificDetails.liverPelds) private liverPelds!: LiverPeldLab[];
  @State(state => state.organSpecificDetails.selectedLiverPeld) private selectedPeld!: LiverPeldLab;

  // If showMeld is true then that means the recipient is an adult and all the fields should be disabled
  @Getter('showMeld', { namespace: 'labs' }) private showMeld!: boolean;
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;  
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('isLastEntry', { namespace: 'utilities' }) private isLastEntry!: (id: ObjectId, entries: any[]) => boolean;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  /**
   * Return true if we can edit the selected measurement
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.showMeld) {
      return false;
    }

    if (!!this.selectedPeld && !!this.liverPelds) {
      if (this.selectedPeld._id) {
        return this.isLastEntry(this.selectedPeld._id, this.liverPelds);
      }
    }
    return true;
  }

  // hide form only if is readonly
  get showPeldForm(): boolean {
    return this.editState?.sodiumPeld?._id !== undefined || !this.showMeld;
  }

  // Load Peld procedures from the API then initialize
  private loadPelds(): void {
    const _id = this.recipient.client_id;
    this.$store.dispatch('organSpecificDetails/loadLiverPelds', _id).then(() => {
      this.$emit('loaded', 'liverPelds');
    }).catch(() => {
      console.warn('Could not load recipient liver PELD labs.');
    });
  }

  // Gets table data for recipient liver Peld labs
  get peldRows(): LiverPeldRow[] {
    if (!this.liverPelds || this.liverPelds.length < 0) {
      return [];
    }
    const result: LiverPeldRow[] = [];
    this.liverPelds.forEach((peldLab: LiverPeldLab) => {
      const row: LiverPeldRow = {
        _id: peldLab._id,
        dateOfPeldData: this.parseDisplayDateUi(peldLab.test_date) || '-',
        peldExpiryDate: this.parseDisplayDateUi(peldLab.expiry_date) || '-',
        serumBilirubin: peldLab.serum_bilirubin == undefined ? '-' : peldLab.serum_bilirubin.toFixed(1).toString() || '-',
        inr: peldLab.inr == undefined ? '-' : peldLab.inr.toFixed(1).toString(),
        albumin: peldLab.albumin == undefined ? '-' : peldLab.albumin.toFixed(1).toString(),
        growthFailure: peldLab.growth_failure ? 'yes' : 'no',
        peldScore: peldLab.peld_score == undefined ? '-' : peldLab.peld_score.toFixed(1).toString(),
      };
      result.push(row);
    });
    return result;
  }

  // Loads a form edit state based on the lab, or a new state if there is none
  private initializeSodiumPeldForm(lab?: LiverPeldLab): void {
    // Clear table selections from subsection component
    this.$store.commit('organSpecificDetails/selectLiverPeld', lab);
    // Initialize subsection component form edit states
    this.$store.commit('pageState/set', {
      pageKey: 'liverDetails',
      componentKey: 'sodiumPeld',
      value: this.buildSodiumPeldForm(lab),
    });
  }

  /**
   * Extract relevant parts from recipient journey to match the form layout
   */
  private extractLiverSpecificDetailsForm(journey: RecipientJourney): LiverSpecificForm {
    if (!journey) {
      const result: LiverSpecificForm = { exceptionPoints: {} };
      return result;
    }
    const organ_specific_details = journey.organ_specific_details as LiverDetails;
    const result: LiverSpecificForm = {
      exceptionPoints: {},
    };
    return result;
  }

  /**
   * Returns Sodium Meld form edit state based on recipient Sodium Meld lab document
   *
   * @param lab PELD lab document fetched from API
   * @returns {SodiumMeldForm} editable PELD form state
   */
  public buildSodiumPeldForm(lab?: LiverPeldLab): SodiumPeldForm {
    if (!lab) {
      return {
        dateOfPeldData: this.currentDateUi(),
        growthFailure: false
      };
    }
    return {
      _id: lab._id,
      dateOfPeldData: this.parseDateUi(lab.test_date),
      peldExpiryDate: this.parseDateUi(lab.expiry_date),
      serumBilirubin: lab.serum_bilirubin === undefined ? undefined : Number(lab.serum_bilirubin.toFixed(1)),
      inr: lab.inr  === undefined ? undefined : Number(lab.inr.toFixed(1)),
      albumin: lab.albumin,
      growthFailure: lab.growth_failure,
      peldScore: lab.peld_score,
    };
  }

  get peldTableConfig(): TableConfig {
    return {
      data: this.peldRows,
      columns: [
        { label: 'Date of PELD data', field: 'dateOfPeldData', width: '20%' },
        { label: 'Total Bilirubin', field: 'serumBilirubin', width: '20%' },
        { label: 'INR', field: 'inr', width: '10%' },
        { label: 'Albumin', field: 'albumin', width: '10%' },
        { label: 'Growth Failure', field: 'growthFailure', width: '10%' },
        { label: 'PELD Score', field: 'peldScore', width: '10%' },
      ],
      empty: 'Use the form below to add a new PELD',
      createButton: !this.showMeld && !this.newJourney,
      createText: 'Create PELD',
      pagination: true,
    };
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: {[key: string]: string} = {
    'liver_peld_lab.test_date'        : 'lsd-peld-date',
    'liver_peld_lab.serum_bilirubin'  : 'lsd-peld-bilirubin',
    'liver_peld_lab.inr'              : 'lsd-peld-inr',
    'liver_peld_lab.albumin'          : 'lsd-peld-albumin',
    'liver_peld_lab.growth_failure'   : 'lsd-peld-growth',
    'liver_peld_lab.peld_score'       : 'lsd-peld-score',
  };

  // PRIVATE

  //  Builds form edit state based on selected document
  private selectPeld(event: any): void {
    // Get selected ID from the table row reference in the select event
    const selectedId = event.row._id && event.row._id.$oid ? event.row._id!.$oid : undefined;
    if (!selectedId || !this.liverPelds) {
      return;
    }
    // Find the selected source document
    const found = this.liverPelds.find((each: LiverPeldLab) => {
      return each._id && each._id.$oid === selectedId;
    });
    if (!found) {
      return;
    }
    // Build form state based on selected document
    this.initializeSodiumPeldForm(found);
  }

  // Build an empty new form edit state
  private createPeld(): void {
    // Build form state
    this.initializeSodiumPeldForm();
    this.$emit('clear');
    this.resetSaveToolbar();

  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const gci = this.$refs.saveSodiumPeld as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    gci.resetSaveToolbar();
  }



  // Saves current form state for Sodium PELD
  private saveSodiumPeld(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveSodiumPeld as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'sodiumPeld');
    // Generate payload based on current edit state
    const sodiumPeldPayload = {
      id: !!this.selectedPeld ? this.selectedPeld._id : undefined,
      recipientId: this.recipient.client_id,
      sodiumPeld: this.extractSodiumPeldPatch(),
    };
    // Dispatch save action and register the response
    this.$store.dispatch('organSpecificDetails/saveSodiumPeld', sodiumPeldPayload).then((success: SaveResult) => {
      // If successful reload table data, clear form, and show success notification
      this.initializeSodiumPeldForm();
      this.loadPelds();
      saveProvider.registerSaveResult(success);
      // Clear any errors
      this.$emit('clear');
      // Request card-section to reload sub-sections related to Liver Scores simultaneously
      this.$emit('reloadLiverExceptionPoints');
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  // Returns a patch object containing changes for a Liver Peld Lab document
  private extractSodiumPeldPatch(): LiverPeldLab {
    if (!this.editState || !this.editState.sodiumPeld) {
      return {};
    }
    const sodiumPeld = this.editState.sodiumPeld;
    return {
      test_date: this.sanitizeDateApi(sodiumPeld.dateOfPeldData),
      serum_bilirubin: sodiumPeld.serumBilirubin,
      inr: sodiumPeld.inr,
      albumin: sodiumPeld.albumin,
      growth_failure: sodiumPeld.growthFailure ? true : false,
      peld_score: sodiumPeld.peldScore,
    };
  }
}

