import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { RecipientJourney, AssessmentState, JourneyStatusConfig, RecipientWaitlistAttributes, WaitlistInactiveStatusValue, JourneyStage, WaitlistActiveStatusValue, PostTransplantState  } from '@/store/recipientJourney/types';
import { Recipient, RecipientsState, RecipientMeasurement, RecipientOopTransplant, RecipientProfileDetails, RecipientAddress, RecipientValidations, SignificantEventDecision, RecipientFinancialAttachment } from '@/store/recipients/types';
import { titleCase, calculateAge, isMasked} from "@/utils";
import JourneyStatus from '@/assets/config/journeyStatus.json';
import { OrganCodeValue } from '../lookups/types';
import { SmallBowelDetails } from '../organSpecificDetails/types';

// Helper methods for getters
function recipientAddress(recipient?: Recipient, addressType?: string): RecipientAddress|undefined {
  if (!recipient || !addressType || !recipient.patient_profile || !recipient.patient_profile.addresses) {
    return undefined;
  }
  const result = recipient.patient_profile.addresses.find((address: any) => { return address.type === addressType; });
  if (!result) {
    return undefined;
  }
  return result;
}

// Getters
export const getters: GetterTree<RecipientsState, RootState> = {
  show(state): Recipient|undefined {
    return state.selectedRecipient;
  },
  recipientDisplayName(state): string {
    if (state.selectedRecipient && state.selectedRecipient.patient_profile) {
      return [state.selectedRecipient.patient_profile!.first_name, state.selectedRecipient.patient_profile!.last_name].join(' ');
    } else {
      return '';
    }
  },
  // TODO: refactor all getters for client ID to use this
  clientId(state): string {
    if (state.selectedRecipient && state.selectedRecipient.client_id) {
      return state.selectedRecipient.client_id.toString();
    } else {
      return '';
    }
  },
  permanentAddress(state): RecipientAddress|undefined {
    return recipientAddress(state.selectedRecipient, 'permanent');
  },
  ontarioAddress(state): RecipientAddress|undefined {
    return recipientAddress(state.selectedRecipient, 'local');
  },
  recipientJourneys(state): RecipientJourney[] | undefined {
    if (state.selectedRecipient) {
      return state.selectedRecipient.journeys;
    }
  },
  isOopRecipient(state) {
    return state.selectedRecipient && state.selectedRecipient.oop_recipient;
  },
  showList(state): Recipient[]|undefined {
    return state.recipientsList;
  },
  selectedRecipientJourneysList(state, getters, rootState, rootGetters): { id: string; name: string, organCode: number }[] {
    if (!state.selectedRecipient) {
      return [];
    } else if (!state.selectedRecipient.journeys || state.selectedRecipient.journeys.length <= 0) {
      return [];
    } else {
      // Remove Out of Province Journeys from Recipient Journeys list
      const recipient = state.selectedRecipient;
      const inProvinceJourneys = state.selectedRecipient.journeys.filter((journey: RecipientJourney) => {
        return recipient.oop_recipient || !journey.oop_journey;
      });
      // Remove completed journeys from list
      const filtered = inProvinceJourneys.filter((journey: RecipientJourney) => {
        return !journey.completed;
      });
      const organs:  { id: string; name: string, organCode: number }[] = [];
      filtered.forEach((journey) => {
        if (journey.organ_code !== undefined) {
          let organ = rootGetters["lookups/lookupValue"](journey.organ_code, 'organ');
          if(journey.organ_code == OrganCodeValue.SmallBowel) 
          {
            const stomachIncluded = rootGetters["recipients/includeStomach"](journey._id?.$oid);
            if(stomachIncluded) organ = organ + ' + STOMACH';
          }

          organs.push({
            id: journey._id!.$oid,
            name: organ,
            organCode: journey.organ_code,
          });
        }
      });
      return organs;
    }
  },
  includeStomach(state){
    return(journeyId: string):boolean => {
      let stomachIncluded = false;
      if (state.selectedRecipient) {
        const journey = state.selectedRecipient.journeys?.filter((journey: RecipientJourney) => {
          return journey._id?.$oid == journeyId;
        });
        if (!journey) return false;
        journey.forEach((journey) => {
          if (journey.organ_code == OrganCodeValue.SmallBowel){
            const smallBowelSpecificDetails: SmallBowelDetails|undefined = journey?.organ_specific_details;
            stomachIncluded = !!smallBowelSpecificDetails?.stomach_included;
          }
        });
       }
      return stomachIncluded;
    };
  },
  journeyName(state,getters, rootState, rootGetters){
    return(organCode: number, journeyId: string): string => {
      if (!organCode) {
        return '';
      }
      const stomachIncluded = rootGetters["recipients/includeStomach"](journeyId);
      let organName = rootGetters['lookups/lookupValue'](organCode, 'organ');

      if(stomachIncluded && organCode == OrganCodeValue.SmallBowel) organName = organName + ' + STOMACH';
      return organName;
    };
  },
  new(): Recipient {
    return {
      _id: { $oid: '' },
      state: '',
      patient_profile: {
        first_name: '',
        middle_name: '',
        last_name: '',
        ethnicity_code: '',
        ethnicity: '',
        sex: '',
        birth: {
          city: '',
          province: '',
          country: '',
          date: ''
        },
        insurance: {
          number: ''
        },
        addresses: [],
        contact_details: {
          phone_primary: '',
          phone_alternative: '',
          phone_mobile: '',
          phone_pager: '',
          email: ''
        },
        ctr: {
          national_recipient_id: '',
          now_consent_withdrawn: true,
          national_recipient_serum_id: 0,
          ctr_last_updated: '',
        },
        comments: '',
      },
      death: {},
      physicians: {
        attending: {},
        family: {},
        referring: {}
      },
      diagnostics: {
        blood: undefined,
        hla: {}
      },
      measurements: [],
      journeys: [],
      client_id: undefined
    };
  },
  decisionEvents(state: RecipientsState): SignificantEventDecision[]|undefined {
    return state.decisionEvents;
  },
  previousTransplants(state: RecipientsState): RecipientOopTransplant[]|undefined {
    // Fetch all the recipient's Out of Province Journeys
    const outOfProvinceJourneys = state.outOfProvinceJourneys;
    if (!outOfProvinceJourneys) {
      return undefined;
    }
    // Map out of province journeys to previous transplants
    const previousTransplants = outOfProvinceJourneys.map((journey: RecipientJourney): RecipientOopTransplant => {
      const stageAttributes = journey.stage_attributes || {};
      const transplant = stageAttributes.transplant || {};
      const factors = transplant.factors || {};
      const oop = journey.oop_info || {};
      return {
        journeyId: journey._id,
        organ_code: journey.organ_code,
        organ_code_other: oop.oop_organ_other,
        transplant_date: factors.transplant_date || undefined,
        province: oop.oop_province || undefined,
        country: oop.oop_country || undefined,
        facility: oop.oop_hospital || undefined,
        country_other: oop.oop_country_other || undefined,
        dialysis_start_date: stageAttributes.waitlist?.factors?.dialysis_start_date || undefined
      };
    });
    return previousTransplants;
  },
  isUrgent(state: RecipientsState): boolean {
    const recipient = state.selectedRecipient;
    return recipient && recipient.urgent || false;
  },

  /**
   * Return journey(s) based on a given status param
   *
   * Using the JourneyStatus lookup, return all journey(s) that match the status
   *
   * @param status the status of the journey you want
   * @returns {RecipientJourney[]} all inactive RecipientJourney
   */
  getJourneysByStatus(state, getters, rootState, rootGetters): (status: string) => RecipientJourney[] {
    return (status: string): RecipientJourney[] => {
      if (!state.selectedRecipient || !state.selectedRecipient.journeys || !status) {
        return [];
      }
      const recipientJourneys = state.selectedRecipient.journeys || [];
      const oop_recipient = state.selectedRecipient?.oop_recipient;
      // Set cluster links between organs
      let cluster_count = 1;
      recipientJourneys.map((journey: RecipientJourney) => {
        const journeys= rootGetters["journeyState/relatedJourneysIncludingSelf"](journey);
        const isClustered = journeys.length > 1 ? true : false;
        if(isClustered) {
          const journey_with_ui_cluster_id = journeys.find((recipientJourney: RecipientJourney) => {
            return recipientJourney.ui_cluster_id ? recipientJourney.ui_cluster_id : null;
          });
            
          if(!journey_with_ui_cluster_id) {
            journey.ui_cluster_id = cluster_count;
            cluster_count++;
          } else {
            journey.ui_cluster_id = journey_with_ui_cluster_id.ui_cluster_id;
          }
        }
      });

      const filteredJourneys = recipientJourneys.filter((journey: RecipientJourney) => {
        // Out of Province complete journeys require looking at different fields
        if (status === 'oop') {
          // This will return completed oop journeys
          return journey.oop_journey === true;
        } else if (status === 'complete') {
          if(isMasked(journey.completed?.toString())) return null;
          // Completed journeys e.g. removed from waitlist appear in completed journey list
          // Completed superceded journey's will appear in post-transplant section
          const is_superceded_post_transplant = journey.completed_reason == PostTransplantState.Superceded && journey.stage == JourneyStage.PostTransplant;
          // Note: don't show in-province recipient OOP journey as 'complete' (cancelled)
          return journey.completed && !is_superceded_post_transplant && !journey.oop_journey;
        } else if(!oop_recipient && journey.oop_journey) {
            //For ontario recipients, hide oop_journey info in sub sections
            return null;
        } else {
          // Anything else we just match the status
          const journeyStage = journey.stage;
          const journeyState = journey.state;
          // find journeys that match our status
          return JourneyStatus.journeyStatus.find((item: JourneyStatusConfig) => {
            // Completed superceded journey's will appear in post-transplant section
            if(journey.completed && journey.completed_reason === PostTransplantState.Superceded && item.stage === journeyStage &&  item.state === journeyState && item.status === status) return item;
            
            return item.stage === journeyStage && 
              item.state === journeyState && 
              item.status === status &&
              !journey.completed;
          });
        }
      });
      return filteredJourneys || [];
    };
  },

  /**
   * Return a journey status display value given a stage and state
   *
   * Using the JourneyStatus lookup return the display value for the status
   *
   * @param journey the journey
   * @returns {string|undefined} the status of the journey or undefined if none
   */
  getJourneyStatusDisplayValue(state, getters, rootState, rootGetters) {
    return (journey: RecipientJourney): string|undefined => {
      const journeyStage = journey.stage; 
      const journeyState = journey.state;
      const consultationState = journey.consultation_state ? journey.consultation_state : journey.stage_attributes?.assessment?.consultation_state;
      if (!journeyStage || !journeyState) {
        return undefined;
      }
      if (isMasked(journey.stage_attributes?.waitlist?.state)) {
        return '*******';
      }
      const journeyStatus = JourneyStatus.journeyStatus.find((item: JourneyStatusConfig) => {
        return item.stage === journeyStage && item.state === journeyState;
      });
      if (journeyStatus && journeyStatus.statusDisplayValue) {
        if(journeyStatus.statusDisplayValue == "Inactive"){
          const wailistStatus = rootGetters["recipients/waitlistStatusDescription"](journey.stage_attributes?.waitlist);
          return wailistStatus ? wailistStatus : journeyStatus.statusDisplayValue;
        }
        if(journey.stage == JourneyStage.Assessment && consultationState == AssessmentState.Delayed){
          const today = rootGetters["utilities/parseDateTimeUi"](new Date().toISOString());
          const consultation_decision_delay_date = journey.stage_attributes?.assessment?.factors?.consultation_decision_delay_end_date ? rootGetters["utilities/parseDateTimeUi"](journey.stage_attributes?.assessment?.factors?.consultation_decision_delay_end_date) : '';
          if(consultation_decision_delay_date && today && today >= consultation_decision_delay_date) {
            return titleCase(AssessmentState.Pending);
          }
          return titleCase(AssessmentState.Delayed);
        }
        return titleCase(journeyStatus.statusDisplayValue);
      } else {
        if(journey.stage == JourneyStage.Waitlist && journey.stage_attributes?.waitlist?.factors?.transplant_in_progress){
          return titleCase(WaitlistActiveStatusValue.TransplantInProgress);
        } else if(journey.stage == JourneyStage.Waitlist && journey.stage_attributes?.waitlist?.factors?.living_donor_transplant_in_progress){
          return titleCase(WaitlistActiveStatusValue.LivingDonorTransplantInProgress);
        }
        return journeyStatus ? titleCase(journeyStatus.state) : undefined;
      }
    };
  },

  waitlistStatusDescription(state, getters, rootState, rootGetters) {
    return (attributes: RecipientWaitlistAttributes): string => {
      const statusDescription: string[] = [];
      // Check each boolean waitlist factor related to holds or suspensions
      const factors = attributes?.factors || {};
      if (factors.on_hold_initial_waitlisted) {
        statusDescription.push(WaitlistInactiveStatusValue.OnHoldInitialWaitlisted);
      }
      if (factors.on_hold_incomplete_data) {
        statusDescription.push(WaitlistInactiveStatusValue.OnHoldIncompleteData);
      }
      if (factors.on_hold_serum_hla_antibody) {
        statusDescription.push(WaitlistInactiveStatusValue.OnHoldSerumHlaAntibody);
      }
      if (factors.on_hold_medical) {
        statusDescription.push(WaitlistInactiveStatusValue.OnHoldMedical);
      }
      if (factors.suspended_medical) {
        statusDescription.push(WaitlistInactiveStatusValue.SuspendedMedical);
      }
      if (factors.suspended_liver_sodium_meld) {
        statusDescription.push(WaitlistInactiveStatusValue.SuspendedLiverSodumMeld);
      }
      if (factors.suspended_liver_hcc) {
        statusDescription.push(WaitlistInactiveStatusValue.SuspendedLiverHcc);
      }
      if (factors.suspended_heart) {
        statusDescription.push(WaitlistInactiveStatusValue.SuspendedHeart);
      }
      if (factors.on_hold_incomplete_cluster) {
        statusDescription.push(WaitlistInactiveStatusValue.OnHoldIncompleteCluster);
      }
      if (factors.on_hold_cluster) {
        statusDescription.push(WaitlistInactiveStatusValue.OnHoldCluster);
      }
      if (factors.suspended_cluster) {
        statusDescription.push(WaitlistInactiveStatusValue.SuspendedCluster);
      }
      if (factors.on_hold_partial_cluster_transplant) {
        statusDescription.push(WaitlistInactiveStatusValue.OnHoldPartialCluster);
      }
      return statusDescription.join(',');
    };
  },

  /**
   * Return a journey stage display value given a stage and state
   *
   * Using the JourneyStage lookup return the display value for the stage
   *
   * @param stage the journey stage
   * @param state the journey state
   * @returns {string|undefined} the stage of the journey or undefined if none
   */
   getJourneyStageDisplayValue(state) {
    return (stage: string|undefined, state: string|undefined): string|undefined => {
      if (!stage || !state) {
        return undefined;
      }
      const journeyStage = JourneyStatus.journeyStatus.find((item: JourneyStatusConfig) => {
        return item.stage === stage && item.state === state;
      });
      if (journeyStage && journeyStage.stageDisplayValue) {
        return titleCase(journeyStage.stageDisplayValue);
      } else {
        return journeyStage ? titleCase(journeyStage.stage) : undefined;
      }
    };
  },

  /**
   * Returns whether any of the recipient's journeys are eligible for transplant
   *
   * This is used to guarantee that any data that was mandatory for that decision cannot be removed afterwards
   *
   * @returns {boolean} true if at least one journey has Assessment Decision = Recipient to be Listed
   */
  hasJourneyWithAssessmentEligible(state): boolean {
    const recipient = state.selectedRecipient || {};
    const journeys = recipient.journeys || [];
    let result = false;
    journeys.forEach((journey: RecipientJourney) => {
      const stageAttributes = journey.stage_attributes || {};
      const assessmentAttributes = stageAttributes.assessment || {};
      const assessmentState = assessmentAttributes.state;
      result = result || assessmentState === AssessmentState.RecipientToBeListed;
    });
    return result;
  },

  financialAttachments(state): { name?: string, id?: string }[]|undefined{
    const attachments = state.selectedRecipient?.attachments || [];
    if(attachments.length > 0){
      const financialAttachments : { name?: string, id?: string }[]  = attachments.map((item : RecipientFinancialAttachment)=>{
        return { 
          name:item.original_filename,
          id: item._id.$oid
        };
      });
      return financialAttachments;
    }
  },
  recipientAge(state): number {
    const recipient = state.selectedRecipient || {};
    const birthDate = recipient.patient_profile?.birth?.date;
    if (birthDate) {
      return calculateAge(birthDate) || 0;
    } else {
      return 0;
    }
  },
  isDeceased(state): boolean {
    return state.selectedRecipient?.death?.death_date || state.selectedDeath?.death_date ? true : false;
  }
};
